import React, { useEffect, useState, useCallback } from "react";
import NavbarDesktop from "../../molecules/NavbarDesktop/NavbarDesktop";
import DropdownMobile from "../../molecules/DropdownMobile/DropdownMobile";
import Logo from "../../atoms/Logo/Logo";
import "./header.scss";
import { throttle } from "../../../utils/throttle";
import LanguageSwitcher from "../../atoms/LanguageSwitcher/LanguageSwitcher";

const Header = () => {
  const [shouldShowActions, setShouldShowActions] = useState(true);
  const [timerId, setTimerId] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogoClick = useCallback((event) => {
    // Prevent the event from bubbling up
    event.stopPropagation();

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // Using useEffect for initial windowWidth setting
    const handleResize = throttle(() => {
      setWindowWidth(window.innerWidth);
    }, 250);

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial width

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleScroll = useCallback(() => {
    const throttledScroll = throttle(() => {
      if (timerId) {
        clearTimeout(timerId);
      }

      const sections = ["about", "treatments", "contact", "faq"];
      let currentSection = null;

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 150 && rect.top + element.clientHeight > 150) {
            currentSection = section;
            break;
          }
        }
      }

      setActiveSection(currentSection);

      // If we're at the top of the page or the dropdown is open, always show actions.
      if (window.scrollY === 0 || isDropdownOpen) {
        setShouldShowActions(true);
        return;
      }

      setShouldShowActions(true);
      const id = setTimeout(() => {
        if (window.scrollY !== 0) {
          // Only hide if not at the top
          setShouldShowActions(false);
        }
      }, 4000); // 4 seconds delay

      setTimerId(id);
    }, 250);
    throttledScroll();
  }, [timerId, isDropdownOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [handleScroll, timerId]);

  return (
    <header
      className={`header ${shouldShowActions ? "is-visible" : "is-hidden"}`}
      role="banner"
    >
      <div className="header__content">
        <div className="left-section">
          {windowWidth > 1450 ? (
            <NavbarDesktop activeSection={activeSection} />
          ) : (
            <DropdownMobile
              isOpen={isDropdownOpen}
              onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
              activeSection={activeSection}
            />
          )}
        </div>
        <div className="center-section">
          <Logo onClick={handleLogoClick} />
        </div>
        <div className="right-section">
          <LanguageSwitcher />
        </div>
      </div>
    </header>
  );
};

export default Header;
