import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "../../atoms/Text/Text"; // Update with the actual path
import Icon from "../../atoms/Icon/Icon";
import faqData from "../../../constants/faqData";
import "./faq.scss"; // Your SCSS file for styling

const Faq = () => {
  const { t } = useTranslation();
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaqIndex((currentIndex) => {
      return currentIndex === index ? null : index;
    });
  };

  return (
    <section id="faq" className="faq">
      <Text variant="h1" className="faq-title">
        {t("faq.header")}
      </Text>

      <Icon name="lifebuoy" label="Lifebuoy" className="lifebuoy-icon" />

      <div className="faq-list">
        {faqData.map((faq, index) => {
          return (
            <div key={index} className="faq-item">
              <button
                className={`faq-question ${
                  openFaqIndex === index ? "open" : ""
                }`}
                onClick={toggleFaq.bind(null, index)}
                aria-expanded={openFaqIndex === index}
                aria-controls={`faq-answer-${index}`}
              >
                <Text variant="p">{t(faq.question)}</Text>
                <div className="faq-icon">
                  {openFaqIndex === index ? (
                    <Icon
                      name="circleminus"
                      label="Close question"
                      className="circleminus-icon"
                    />
                  ) : (
                    <Icon
                      name="circleplus"
                      label="Expand question"
                      className="circleplus-icon"
                    />
                  )}
                </div>
              </button>
              <div
                id={`faq-answer-${index}`}
                className={`faq-answer ${openFaqIndex === index ? "open" : ""}`}
                hidden={openFaqIndex !== index}
              >
                <Text variant="p">{t(faq.answer)}</Text>
              </div>
            </div>
          );
        })}
      </div>
      <Text variant="p" className="otherquestion">
        {t("faq.otherquestion")}
      </Text>
    </section>
  );
};

export default Faq;
