// Footer.js
import React from "react";
import Text from "../../atoms/Text/Text";
import Icon from "../../atoms/Icon/Icon";
import { useTranslation } from "react-i18next";
import "./footer.scss";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="socials">
        <div className="social-icons">
          <Icon
            name="instagram"
            link="https://www.instagram.com/headwellness/"
            label="Instagram"
            className="instagram-icon"
          />
          <Icon
            name="tiktok"
            link="https://www.tiktok.com/@headwellness"
            label="TikTok"
            className="tiktok-icon"
          />
          <Icon
            name="facebook"
            link="https://www.facebook.com/headwellness"
            label="Facebook"
            className="facebook-icon"
          />
        </div>
      </div>
      <div className="copyrights">
        <Text variant="p" className={"copyright"}>
          {t("footer.rights")}
        </Text>
        <Text variant="p" className={"reserved"}>
          {t("footer.reserved")}
        </Text>
      </div>
      <Text variant="p" className={"kvk"}>
        {t("footer.kvk")}
      </Text>
      <Text variant="p" className={"btw"}>
        {t("footer.btw")}
      </Text>

      <div className="footer-links">
        <Text variant="p" className="link-wrapper">
          <a href="/terms-and-privacy#terms-section" className="link">
            {t("footer.terms")}
          </a>
        </Text>
        <Text variant="p" className="link-wrapper">
          <a href="/terms-and-privacy#privacy-policy-section" className="link">
            {t("footer.policy")}
          </a>
        </Text>
      </div>
      <Text variant="p" className={"credits"}>
        <a href="mailto:contact@devhise.com" className="email-link">
          Powered by <span className="bold">DevHISE</span>{" "}
        </a>
      </Text>
    </footer>
  );
};

export default Footer;
